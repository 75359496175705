import React, { Component } from 'react';

export class Publication extends Component {
    static displayName = Publication.name;

    constructor(props) {

        super(props);
        this.state = {
            publicationLink: "https://drive.google.com/file/d/1pyZSY9jbhqodc0jm0CjvKEbDBDy1HuQh/view?usp=sharing"
        };
    }

    render() {

        return (
            <div style={{ paddingTop: "50px", paddingBottom: "50px",backgroundColor: "#9bf2fa" }}>
                <section id="title">
                    <div class="margin-centerText">
                        <div style={{ fontSize: '24px' }}>
                            <div style={{ fontSize: '24px' }}>
                                <strong>Publication</strong>

                                <hr style={{ width: '10%', margin: 'auto' }}></hr>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="content" class="section-award" >
                    <div class="margin-centerText">
                        <div class="margin-centerText">
                            <h4>All extended abstracts have been published</h4>
                            <br />
                            <a href={this.state.publicationLink}><button class="button register"><strong>Click here</strong></button></a>
                        </div>
                    </div>
                </section>

      </div>
    );
  }
}

import React, { Component } from 'react';
import { title, themeContent, inceptionandPurposeContent, innovationContent, connectingContent, growthContent, movementContent } from '../content/HomeContent';
import Category from './Home/Category';
import CustomFooter from './CustomFooter';
import AboutUs from './Home/AboutUs';
import CollaborationPartner from './Home/CollaborationPartner';
import Sponsorship from './Home/Sponsorship ';
import Countdown from './Home/Countdown';
import registration_close_poster from './../image/registration_closed_poster.jpg';

export class HomeBoard extends Component {
    static displayName = HomeBoard.name;

    constructor(props) {

        super(props);
        this.state = {
            title: title,
            themeContent: themeContent,
            inceptionandPurposeContent: inceptionandPurposeContent,
            innovationContent: innovationContent,
            connectingContent: connectingContent,
            growthContent: growthContent,
            movementContent: movementContent
        };
    }
    render() {

        return (
            <div >
                <div class="section-full">

                    <div class="customBackgroundImage">
                    </div>

                    {/*<section id="title" class="section-title">*/}
                    {/*    <div class="margin-centerText">*/}
                    {/*        <h1 style={{ fontFamily: "Kozuka Gothic Pro H", fontWeight: 'bold' }}>{this.state.title}</h1>*/}
                    {/*        <h1 style={{ fontFamily: "Kozuka Gothic Pro H", fontWeight: 'bold' }}>(JIIICaS 2024)</h1>*/}
                    {/*    </div>*/}
                    {/*</section>*/}
                    <br />
                    <br />
                    {/*<div class="margin-centerText">*/}
                    {/*    <img class="scaleCategoryComparison" src={registration_close_poster} alt="event_tetantive" />*/}
                    {/*</div>*/}
                    <br />
                    <section id="/#aboutus">
                    <AboutUs/>
                    </section>

                    <section id="/#category" class="section-category" style={{ backgroundColor: "#9bf2fa" }} >
                        <Category/>
                    </section>

                    <section id="countdown" class="section-countdown" >
                        <Countdown />
                    </section>

                    <CollaborationPartner />
                    <Sponsorship/>
                    <CustomFooter />

                </div>
        {/*<p>Welcome to your new single-page application, built with:</p>*/}
        {/*<ul>*/}
        {/*  <li><a href='https://get.asp.net/'>ASP.NET Core</a> and <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> for cross-platform server-side code</li>*/}
        {/*  <li><a href='https://facebook.github.io/react/'>React</a> for client-side code</li>*/}
        {/*  <li><a href='http://getbootstrap.com/'>Bootstrap</a> for layout and styling</li>*/}
        {/*</ul>*/}
        {/*<p>To help you get started, we have also set up:</p>*/}
        {/*<ul>*/}
        {/*  <li><strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return here.</li>*/}
        {/*  <li><strong>Development server integration</strong>. In development mode, the development server from <code>create-react-app</code> runs in the background automatically, so your client-side resources are dynamically built on demand and the page refreshes when you modify any file.</li>*/}
        {/*  <li><strong>Efficient production builds</strong>. In production mode, development-time features are disabled, and your <code>dotnet publish</code> configuration produces minified, efficiently bundled JavaScript files.</li>*/}
        {/*</ul>*/}
        {/*<p>The <code>ClientApp</code> subdirectory is a standard React application based on the <code>create-react-app</code> template. If you open a command prompt in that directory, you can run <code>npm</code> commands such as <code>npm test</code> or <code>npm install</code>.</p>*/}
      </div>
    );
  }
}

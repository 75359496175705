import React, { Component } from 'react';

export class Award extends Component {
    static displayName = Award.name;

    constructor(props) {

        super(props);
        this.state = {
            awardLink:"https://drive.google.com/drive/folders/1BdZFduwHX-fYTfc4EHn3ZT-FbvxW6IPC?usp=sharing"
        };
    }

    render() {

        return (
            <div>
                <section id="title">
                    <div class="margin-centerText">
                        <div style={{ fontSize: '24px' }}>
                            <div style={{ fontSize: '24px' }}>
                                <strong>Awards</strong>

                                <hr style={{ width: '10%', margin: 'auto' }}></hr>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="content" class="section-award">
                    <div class="margin-centerText">
                        <h4>JIIICaS 2024 Full Results</h4>
                        <br/>
                        <a href={this.state.awardLink}><button class="button register"><strong>Click here</strong></button></a>
                    </div>
                </section>

      </div>
    );
  }
}

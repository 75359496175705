import React, { Component } from 'react';
import event_tetantive from '../../image/event_tetantive.jpg';

export class PosterEventTentative extends Component {
    static displayName = PosterEventTentative.name;

    constructor(props) {

        super(props);
        this.state = {

        };
    }

    render() {

        return (
            <div style={{ paddingTop: "50px", paddingBottom: "50px", backgroundColor: "#9bf2fa" }}>
                <section id="title">
                    <div class="margin-centerText">
                        <div style={{ fontSize: '24px' }}>
                            <div style={{ fontSize: '24px' }}>
                                <strong>Event Tentative</strong>

                                <hr style={{ width: '10%', margin: 'auto' }}></hr>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="margin-centerText">
                    <img class="scaleRegistrationPoster" src={event_tetantive} alt="event_tetantive" />
                </div>
            </div>
    );
  }
}
